import React from "react";

export const PrevArrow = () => {

    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39.5 20.3052C39.5 31.0747 30.7696 39.8052 20 39.8052C9.23044 39.8052 0.499992 31.0747 0.499995 20.3052C0.499998 9.53562 9.23045 0.805177 20 0.805178C30.7696 0.805178 39.5 9.53563 39.5 20.3052Z"
                fill="white" stroke="#ED0E00"/>
            <path d="M27.619 20.3052L12.3809 20.3052M12.3809 20.3052L17.4603 26.0195M12.3809 20.3052L17.4603 14.591"
                  stroke="#ED0E00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};