import {
    beautyTitles,
    cakeTitles, chemistryTitles,
    cookingTitles,
    drinksTitles, feedTitles, fruitTitles, groceryTitles,
    meatTitles,
    milkTitles,
    semifinishedTitles
} from "./sponsors_titles";

export const allSponsors = [
    {
        title: 'Напитки, чай, кофе',
        path: 'images/sponsors/drinks/',
        number: 63,
        titles: drinksTitles,
    },
    {
        title: 'Кондитерские изделия',
        path: 'images/sponsors/cake/',
        number: 38,
        titles: cakeTitles,
    },
    {
        title: 'Молочные продукты',
        path: 'images/sponsors/milk/',
        number: 7,
        titles: milkTitles,
    },
    {
        title: 'Мясо, рыба, птица',
        path: 'images/sponsors/meat/',
        number: 29,
        titles: meatTitles,
    },
    {
        title: 'Полуфабрикаты',
        path: 'images/sponsors/semifinished/',
        number: 7,
        titles: semifinishedTitles,
    },
    {
        title: 'Овощи и фрукты',
        path: 'images/sponsors/fruits/',
        number: 6,
        titles: fruitTitles,
    },
    {
        title: 'Бакалея, соусы, масло',
        path: 'images/sponsors/grocery/',
        number: 8,
        titles: groceryTitles,
    },
    {
        title: 'Кулинария',
        path: 'images/sponsors/cooking/',
        number: 1,
        titles: cookingTitles,
    },
    {
        title: 'Корма для животных',
        path: 'images/sponsors/feed/',
        number: 20,
        titles: feedTitles,
    },
    {
        title: 'Красота и здоровье',
        path: 'images/sponsors/beauty/',
        number: 16,
        titles: beautyTitles,
    },
    {
        title: 'Бытовая химия',
        path: 'images/sponsors/chemistry/',
        number: 5,
        titles: chemistryTitles,
    },
]