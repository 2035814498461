import React from "react";

export const SuccessIcon = () => {

    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                stroke="#05BF4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.5 9.5L12 15L24.5 2.5" stroke="#05BF4F" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
};