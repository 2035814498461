import React from "react";

export const NextArrow = () => {

    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.499996 20.3052C0.499999 9.53562 9.23045 0.805174 20 0.805176C30.7696 0.805178 39.5 9.53563 39.5 20.3052C39.5 31.0747 30.7696 39.8052 20 39.8052C9.23044 39.8052 0.499992 31.0747 0.499996 20.3052Z"
                fill="white" stroke="#ED0E00"/>
            <path d="M12.3809 20.3051L27.619 20.3051M27.619 20.3051L22.5396 14.5908M27.619 20.3051L22.5396 26.0194"
                  stroke="#ED0E00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};