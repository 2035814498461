import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import commonStyles from "./styles.module.scss";
import styles from "./Winners.module.scss";
import {ElementWrapper} from "../../components/ElementWrapper";
import cc from "classnames";
import {PrevArrow} from "../../components/PrevArrow";
import {NextArrow} from "../../components/NextArrow";
import {Loupe} from "../../components/Loupe";
import {YellowBalloon} from "../../components/balloons/YellowBalloon";
import {SmallYellowBalloon} from "../../components/balloons/SmallYellowBalloon";
import {BlueBalloon} from "../../components/balloons/BlueBalloon";
import {Star} from "../../components/Star";
import {IState, IWinners} from "../../types";
import {Cross} from "../../components/Cross";
import {getWinners} from "../MainPage.thunk";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentIndex, setShownWinners} from "../MainPageAC";
import {yandexMetrikaHandler} from "../../helpers/utils";
import {Minus} from "../../components/Minus";
import {Plus} from "../../components/Plus";

export const Winners = () => {

    const dispatch = useDispatch();

    const winnersData: any = useSelector((state: IState) => state.mainPage);

    const [isTableOpen, setIsTableOpen] = useState<boolean>(false)

    const handleTableOpen = () => {
        setIsTableOpen(prev => !prev)
    }

    const {currentIndex, winners, winnersForShow} = winnersData;

    const onChangeCurrentIndex = (diff: number) => () => {
        if (currentIndex + diff > winners?.length - 1 || currentIndex + diff < 0) return;
        inputPhoneRef.current.value = null;
        inputCardRef.current.value = null;
        inputPrizeRef.current.value = null;

        dispatch(setCurrentIndex(currentIndex + diff));
        dispatch(setShownWinners(winners[currentIndex + diff]?.winners));
    }

    const onClickCross = (type: string) => () => {
        if (type === 'id') {
            inputPhoneRef.current.value = null;
        } else if (type === 'card') {
            inputCardRef.current.value = null;
        } else if (type === 'prize') {
            inputPrizeRef.current.value = null;
        }
        dispatch(setShownWinners(winners[currentIndex]?.winners));
    }

    const inputPhoneRef: React.MutableRefObject<any> = useRef(null);

    const changeFocusPhoneInput = () => {
        inputPhoneRef.current?.focus();
    }

    const inputCardRef: React.MutableRefObject<any> = useRef(null);

    const changeFocusCardInput = () => {
        inputCardRef.current?.focus();
    }

    const inputPrizeRef: React.MutableRefObject<any> = useRef(null);

    const changeFocusPrizeInput = () => {
        inputPrizeRef.current?.focus();
    }

    const filterData = (type: keyof IWinners) => ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        if (type === 'id') {
            inputCardRef.current.value = null;
            inputPrizeRef.current.value = null;
        } else {
            inputPhoneRef.current.value = null;
            inputPrizeRef.current.value = null;
        }

        const filterData = winners[currentIndex]?.winners?.filter(
            (el: any) => el[type]?.includes(value));
        dispatch(setShownWinners(filterData));
    }

    const filterStringData = (type: keyof IWinners) => ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        inputPhoneRef.current.value = null;
        inputCardRef.current.value = null;

        const lowCaseValue = value.toLowerCase();

        const filterData = winners[currentIndex]?.winners?.filter(
            (el: any) => el[type]?.toLowerCase().includes(lowCaseValue));
        dispatch(setShownWinners(filterData));
    }

    useEffect(() => {
        // @ts-ignore
        dispatch(getWinners());
    }, [dispatch]);

    return (
        <ElementWrapper>
            <div className={commonStyles.title}>
                Победители
            </div>
            <div className={cc(commonStyles.description, commonStyles.sponsors_description)}>
                {'Мы свяжемся с победителями: позвоним и отправим письмо. Если возникнут вопросы, напишите нам: '}
                <a className={styles.red_text}
                   href={'mailto:magnit2@equilon.ru'}
                   onClick={() => yandexMetrikaHandler('emailClick')}
                >
                    magnit2@equilon.ru
                </a>
            </div>

            <div className={styles.weeks_title}>
                <div className={styles.icon}
                     onClick={onChangeCurrentIndex(-1)}>
                    <PrevArrow/>
                </div>

                <div>
                    <div className={styles.week}>
                        {winners[currentIndex]?.title}
                    </div>
                    <div className={styles.dates}>
                        {winners[currentIndex]?.subtitle}
                    </div>
                </div>

                <div className={styles.icon}
                     onClick={onChangeCurrentIndex(1)}>
                    <NextArrow/>
                </div>
            </div>

            <div>
                <div className={styles.first_table_line}>
                    <div className={styles.first_table_line_elem}>
                        <div className={styles.loupe}>
                            <span onClick={changeFocusPhoneInput}>
                                <Loupe/>
                            </span>
                            <span className={styles.find_input}>
                                <input ref={inputPhoneRef}
                                       onChange={filterData('id')}
                                       className={styles.text_input}
                                />
                                {inputPhoneRef.current?.value &&
                                <span className={styles.cross_icon}
                                      onClick={onClickCross('id')}
                                >
                                        <Cross/>
                                    </span>
                                }
                            </span>
                        </div>
                        <div className={styles.first_table_title}>
                            Последние 4 цифры номера телефона
                        </div>
                    </div>
                    <div className={styles.first_table_line_elem}>
                        <div className={styles.loupe}>
                            <span onClick={changeFocusCardInput}>
                                <Loupe/>
                            </span>
                            <span className={styles.find_input}>
                                <input ref={inputCardRef}
                                       onChange={filterData('card')}
                                       className={styles.text_input}
                                />
                                {inputCardRef.current?.value &&
                                <span className={styles.cross_icon}
                                      onClick={onClickCross('card')}
                                >
                                        <Cross/>
                                    </span>
                                }
                            </span>
                        </div>
                        <div className={styles.first_table_title}>
                            Последние 4 цифры карты Магнит
                        </div>
                    </div>
                    <div className={`${styles.first_table_line_elem} ${styles.flex}`}>
                        <div className={styles.left_block}>
                            <div className={styles.loupe}>
                                <span onClick={changeFocusPrizeInput}>
                                    <Loupe/>
                                </span>
                                <span className={styles.find_input}>
                                    <input ref={inputPrizeRef}
                                           onChange={filterStringData('prize')}
                                           className={styles.text_input}
                                    />
                                    {inputPrizeRef.current?.value &&
                                    <span className={styles.cross_icon}
                                          onClick={onClickCross('prize')}
                                    >
                                            <Cross/>
                                        </span>
                                    }
                                </span>
                            </div>
                            <div className={styles.first_table_title}>
                                Приз
                            </div>
                        </div>
                        <div className={styles.open_button} onClick={handleTableOpen}>
                            {isTableOpen ? <Minus/> : <Plus/>}
                        </div>
                    </div>
                </div>

                <div
                    className={styles.winners_block}
                    style={{height: isTableOpen ? `${winnersForShow?.length * 63}px`: 0}}
                >
                    {winnersForShow?.map((el: IWinners, index: number) =>
                        <div className={styles.line}
                             key={index}
                        >
                            <div className={styles.line_elem}>
                                <div>
                                    {el.id || ''}
                                </div>
                            </div>
                            <div className={styles.line_elem}>
                                <div>
                                    {el.card || ''}
                                </div>
                            </div>
                            <div className={styles.line_elem}>
                                <div>
                                    {el.prize || ''}
                                </div>
                            </div>
                        </div>
                    )}
                </div>


            </div>
            <div className={commonStyles.fifth_yellow_balloon}>
                <YellowBalloon/>
            </div>
            <div className={commonStyles.sixth_yellow_balloon}>
                <SmallYellowBalloon/>
            </div>
            <div className={commonStyles.sixth_blue_balloon}>
                <BlueBalloon/>
            </div>
            <div className={cc(commonStyles.thirteenth_star, commonStyles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(commonStyles.fourteenth_star, commonStyles.big_screen)}>
                <Star/>
            </div>
        </ElementWrapper>
    )
};