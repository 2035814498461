import {IAction} from "../types";

const initialState = {
    winners: {
        title: '',
        subtitle: '',
        winners: [
            {
                id: '',
                card: '',
                prize: '',
            }
        ]
    },
    currentIndex: 0,
    winnersForShow: [
        {
            id: '',
            card: '',
            prize: '',
        }
    ]
}

export default function MainPageReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case 'SET_WINNERS':
            return {
            ...state,
            winners: action.payload
        }
        case 'SET_SHOWN_WINNERS':
            return {
                ...state,
                winnersForShow: action.payload
            }
        case 'SET_CURRENT_INDEX':
            return {
                ...state,
                currentIndex: action.payload
            }
        default:
            return state;
    }
}
