import React from "react";
import styles from "./FirstScreen.module.scss";
import {HalfYellowBalloon} from "../../components/balloons/HalfYellowBalloon";
import {BlueBalloon} from "../../components/balloons/BlueBalloon";
import {PartialYellowBalloon} from "../../components/balloons/PartialYellowBalloon";
import {BigPinkBalloon} from "../../components/balloons/BigPinkBalloon";
import {Star} from "../../components/Star";

export const FirstScreen = () => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.side_elem}>
                <div className={styles.half_yellow_balloon}>
                    <HalfYellowBalloon/>
                </div>
                <div className={styles.left_blue_balloon}>
                    <BlueBalloon/>
                </div>
                <img src="images/soap_bubble.png"
                     alt={'Пузырь'}
                     className={styles.left_bubble}
                     decoding={"async"}
                />
            </div>

            <div className={styles.center_elem}>
                <img src="images/soap_bubble.png"
                     alt={'Пузырь'}
                     className={styles.upper_bubble}
                     decoding={"async"}
                />
                <img src="images/soap_bubble.png"
                     alt={'Пузырь'}
                     className={styles.upper_small_bubble}
                     decoding={"async"}
                />
                <img src="images/soap_bubble.png"
                     alt={'Пузырь'}
                     className={styles.big_bubble}
                     decoding={"async"}
                />
                <img src="images/soap_bubble.png"
                     alt={'Пузырь'}
                     className={styles.small_bubble}
                     decoding={"async"}
                />
                <div className={styles.big_pink_balloon}>
                    <BigPinkBalloon/>
                </div>
                <div className={styles.first_star}>
                    <Star/>
                </div>
                <div className={styles.second_star}>
                    <Star/>
                </div>
                <div className={styles.third_star}>
                    <Star/>
                </div>
                <div className={styles.orange_ellipse}/>
                <div className={styles.yellow_ellipse}/>
                <img src="images/soap_bubble_with_money.png"
                     alt={'Пузырь'}
                     className={styles.bubble_with_money}
                     decoding={"async"}
                />
                <img src="images/magnit.png"
                     alt={'День рождения'}
                     className={styles.birthday_image}
                     decoding={"async"}
                />
                <img src="images/title_magnit.png"
                     alt={'День рождения'}
                     className={styles.title_magnit}
                     decoding={"async"}
                />
                <img src="images/logo_magnit.png"
                     alt={'Логотип'}
                     className={styles.logo_magnit}
                     decoding={"async"}
                />
            </div>

            <div className={styles.side_elem}>
                <div className={styles.right_blue_balloon}>
                    <BlueBalloon/>
                </div>
                <div className={styles.partial_yellow_balloon}>
                    <PartialYellowBalloon/>
                </div>
                <img src="images/soap_bubble.png"
                     alt={'Пузырь'}
                     className={styles.right_bubble}
                     decoding={"async"}
                />
            </div>

            <div className={styles.first_band}/>
            <div className={styles.second_band}/>
        </div>
    )
};