import React from "react";

export const BigPinkBalloon = () => {

    return (
        <svg width="183" height="214" viewBox="0 0 183 214" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M110.875 185.075C136.625 178.265 168.305 121.382 156.653 73.7842C147.707 37.2389 107.973 7.49523 69.2956 16.9632C30.6187 26.4312 9.11596 71.1655 18.0621 107.711C28.7587 151.407 80.7626 188.753 107.61 185.684C107.597 185.982 107.722 186.284 107.979 186.485L108.022 186.519C108.414 186.825 108.415 187.428 108.082 187.797C107.21 188.763 106.193 190.442 106.232 192.556C105.704 193.582 105.095 195.87 106.885 196.815C109.122 197.997 112.063 197.277 114.643 195.877C117.222 194.477 119.301 192.623 118.306 190.946C117.311 189.268 116.259 188.949 115.847 188.858C115.741 188.834 115.635 188.709 115.482 188.526C115.051 188.011 114.239 187.042 111.956 186.617C111.843 186.596 111.731 186.562 111.626 186.514C111.076 186.263 110.766 185.67 110.875 185.075Z"
                  fill="#FF76C2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M110.875 185.075C136.625 178.265 168.305 121.382 156.653 73.7842C147.707 37.2389 107.973 7.49523 69.2956 16.9632C30.6187 26.4312 9.11596 71.1655 18.0621 107.711C28.7587 151.407 80.7626 188.753 107.61 185.684C107.597 185.982 107.722 186.284 107.979 186.485L108.022 186.519C108.414 186.825 108.415 187.428 108.082 187.797C107.21 188.763 106.193 190.442 106.232 192.556C105.704 193.582 105.095 195.87 106.885 196.815C109.122 197.997 112.063 197.277 114.643 195.877C117.222 194.477 119.301 192.623 118.306 190.946C117.311 189.268 116.259 188.949 115.847 188.858C115.741 188.834 115.635 188.709 115.482 188.526C115.051 188.011 114.239 187.042 111.956 186.617C111.843 186.596 111.731 186.562 111.626 186.514C111.076 186.263 110.766 185.67 110.875 185.075Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M97.8496 156.87C133.156 148.227 153.865 108.896 144.104 69.0224C138.949 47.9622 126.324 30.9626 110.357 20.8827C97.488 15.3819 83.1958 13.5607 69.0507 17.0233C31.7457 26.1554 10.5092 68.356 17.2997 104.218C28.4169 141.828 63.7074 165.228 97.8496 156.87Z"
                fill="#FF76C2"/>
            <path
                d="M31.0573 75.2583C31.6106 63.9353 38.362 51.0705 42.6887 44.64C42.9902 44.1919 43.4543 43.8793 43.9837 43.7534C46.0015 43.2736 47.591 45.6256 46.514 47.3781C40.108 57.8018 39.1 67.1627 39.6761 72.7131C39.8846 74.7225 39.239 76.8203 37.6238 78.0578C34.8337 80.1956 30.8869 78.7433 31.0573 75.2583Z"
                fill="#FF76C2"/>
            <path
                d="M31.0573 75.2583C31.6106 63.9353 38.362 51.0705 42.6887 44.64C42.9902 44.1919 43.4543 43.8793 43.9837 43.7534C46.0015 43.2736 47.591 45.6256 46.514 47.3781C40.108 57.8018 39.1 67.1627 39.6761 72.7131C39.8846 74.7225 39.239 76.8203 37.6238 78.0578C34.8337 80.1956 30.8869 78.7433 31.0573 75.2583Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};