import React from "react";
import styles from "./InfoPanel.module.scss";
import {AttentionIcon} from "./AttentionIcon";
import {SuccessIcon} from "./SuccessIcon";

type IInfoPanelProps = {
    type: 'attention' | 'success';
    firstParagraph: string;
    secondParagraph?: string;
}

export const InfoPanel = ({
                              type,
                              firstParagraph,
                              secondParagraph
                          }: IInfoPanelProps) => {

    return (
        <div className={styles.wrapper}>
            <span className={styles.icon}>
                {type === 'attention' && <AttentionIcon/>}
                {type === 'success' && <SuccessIcon/>}
            </span>
            <span className={styles.text}>
                <span>
                    {firstParagraph}
                </span>
                {secondParagraph &&
                <span className={styles.second_paragraph}>
                    {secondParagraph}
                </span>
                }
            </span>
        </div>
    )
};