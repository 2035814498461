import React from "react";

export const SimpleRedArrow = () => {

    return (
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 7.80518H19.5M19.5 7.80518L12.5455 14.3052M19.5 7.80518L12.5455 1.30518" stroke="#ED0E00"
                  strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
};