import React, {ReactNode} from "react";
import styles from "./ElementWrapper.module.scss";

type IElementWrapperProps = {
    children?: ReactNode,
}

export const ElementWrapper = ({
                                   children
                               }: IElementWrapperProps) => {

    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    )
};