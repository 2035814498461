import {createRequest} from "../api";
import {AxiosResponse} from "axios";
import {Dispatch} from "redux";
import {setCurrentIndex, setOpenComic, setShownWinners} from "./MainPageAC";
import {prepareWinners} from "../helpers/utils";

export const getWinners = () => (dispatch: Dispatch) => {
    createRequest.get('')
        .then((res: AxiosResponse<string>) => {
            if (res.status >= 200 && res.status < 300) {
                const data = prepareWinners(res.data);
                const currentInd = data?.length - 1;
                dispatch(setOpenComic(data));
                dispatch(setCurrentIndex(currentInd));
                dispatch(setShownWinners(data[currentInd]?.winners));
            }
        })
        .catch((err) => {
            console.error(err);
        });
}