import React from "react";
import styles from "./styles.module.scss";
import {ElementWrapper} from "../../components/ElementWrapper";
import {Arrow} from "../../components/Arrow";
import cc from "classnames";
import {Star} from "../../components/Star";

type IPartnersProps = {
    onClickSponsorsAndPartners: Function
}

const arr = Array.from(Array(6).keys());

export const Partners = ({
                             onClickSponsorsAndPartners
                         }: IPartnersProps) => {

    return (
        <ElementWrapper>
            <div className={styles.title}>
                Партнеры акции
            </div>

            <div className={styles.brands}>
                {
                    arr.map((el) => {
                        return (
                            <div className={styles.brand}
                                 key={el}>
                                <img src={`/images/brands/${el}.png`}
                                     alt={'Партнер акции'}
                                     decoding={"async"}
                                />
                            </div>
                        )
                    })
                }
            </div>

            <a className={styles.open_list}
               onClick={onClickSponsorsAndPartners(1)}
               href={'#sponsors_partners'}>
                <div>
                    <div>
                        Открыть полный список
                    </div>
                    <Arrow/>
                </div>
            </a>

            <div className={cc(styles.eleventh_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.twelfth_star, styles.big_screen)}>
                <Star/>
            </div>

        </ElementWrapper>
    )
};