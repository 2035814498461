import React from "react";
import styles from "./styles.module.scss";
import {ElementWrapper} from "../../components/ElementWrapper";
import {Point} from "../../components/Point";
import {InfoPanel} from "../../components/InfoPanel";
import cc from "classnames";
import {chatBotLink} from "../../helpers/links";
import {YellowBalloon} from "../../components/balloons/YellowBalloon";
import {BlueBalloon} from "../../components/balloons/BlueBalloon";
import {SmallPinkBalloon} from "../../components/balloons/SmallPinkBalloon";
import {Star} from "../../components/Star";
import {yandexMetrikaHandler} from "../../helpers/utils";

export const ActionTerms = () => {

    return (
        <ElementWrapper>
            <div className={styles.title}>
                Условия акции
            </div>

            <div className={styles.description}>
                Покупайте в наших магазинах с картой из приложения Магнит и получайте больше попыток в игре:
            </div>

            <div className={styles.description_terms}>
                <div className={styles.description_terms_elem}>
                    <Point/>
                    <span className={styles.terms_title}>
                        На сумму от 500 ₽ = 1 попытка
                    </span>
                    <div className={styles.terms_content}>
                        в магазинах Магнит Косметик, Магнит у дома, Магнит Аптека
                    </div>
                </div>

                <div className={styles.description_terms_elem}>
                    <Point/>
                    <span className={styles.terms_title}>
                    На сумму от 1000 ₽ = 1 попытка
                </span>
                    <div className={styles.terms_content}>
                        в магазинах Магнит Семейный, Магнит Экстра, Магнит Опт
                    </div>
                </div>

                <div className={styles.description_terms_elem}>
                    <Point/>
                    <span className={styles.terms_title}>
                        Товары-спонсоры = 1 попытка
                    </span>
                </div>
            </div>

            <InfoPanel type={'attention'}
                       firstParagraph={'В сумме не учитываются алкоголь, табачная и никотиносодержащая продукция, а также устройства для нее'}/>

            <div className={cc(styles.sub_title, styles.second_title)}>
                Купили без карты из приложения Магнит?
            </div>

            <InfoPanel type={'success'}
                       firstParagraph={'Забыли отсканировать карту? Не страшно! Отправьте фото чека в чат-бот Telegram в течение 120 часов после покупки.'}
                       secondParagraph={'Найти бот просто: напишите в поиске Бот Магнит или нажмите кнопку ниже'}/>

            <a className={cc(styles.red_button, styles.send_check_button)}
               href={chatBotLink}
               target={'_blank'}
               rel="noreferrer"
               onClick={() => yandexMetrikaHandler('sendCheckClick')}
            >
                <div className={cc(styles.red_button_content, styles.send_check_content)}>
                    Отправить чек в чат-бот
                </div>
            </a>

            <InfoPanel type={'attention'}
                       firstParagraph={'В сутки в чат-бот можно отправлять не более 2х чеков из одной торговой точки'}/>

            <div className={styles.third_yellow_balloon}>
                <YellowBalloon/>
            </div>
            <div className={styles.third_blue_balloon}>
                <BlueBalloon/>
            </div>
            <div className={styles.third_pink_balloon}>
                <SmallPinkBalloon/>
            </div>
            <img src="images/soap_bubble.png"
                 alt={'Пузырь'}
                 className={styles.third_soap_bubble}
                 decoding={"async"}
            />
            <img src="images/soap_bubble.png"
                 alt={'Пузырь'}
                 className={styles.fourth_soap_bubble}
                 decoding={"async"}
            />
            <img src="images/soap_bubble.png"
                 alt={'Пузырь'}
                 className={styles.fifth_soap_bubble}
                 decoding={"async"}
            />
            <div className={cc(styles.seventh_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.eighth_star, styles.big_screen)}>
                <Star/>
            </div>
        </ElementWrapper>
    )
};