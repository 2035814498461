import React, {useState} from "react";
import styles from "./SponsorElem.module.scss";
import {Plus} from "./Plus";
import {Minus} from "./Minus";
import {ImagesWithTitles} from "./ImagesWithTitles";

type ISponsorElemProps = {
    title: string;
    path: string;
    number: number;
    titles: Array<string>;
}

export const SponsorElem = ({
                                title,
                                path,
                                number,
                                titles,
                            }: ISponsorElemProps) => {

    const [showImages, setShowImages] = useState(false);

    const changeShowImages = () => {
        setShowImages(prevState => !prevState);
    }

    return (
        <div>
            <div className={styles.title}
                 onClick={changeShowImages}>
                <span className={styles.text}>
                    {title}
                </span>
                <div className={styles.icon}>
                {showImages ?
                    <Minus/> :
                    <Plus/>
                }
                </div>
            </div>

            {showImages &&
            <div className={styles.images}>
                <ImagesWithTitles path={path}
                                  number={number}
                                  titles={titles}/>
            </div>
            }
        </div>
    )
};