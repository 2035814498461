import React, {useEffect, useMemo, useState} from "react";
import styles from "./MainPage.module.scss";
import {FirstScreen} from "./components/FirstScreen";
import {BasicPart} from "./components/BasicPart";
import {FullLogoListPage} from "./components/FullLogoListPage/FullLogoListPage";
import {SimpleRedArrow} from "../components/SimpleRedArrow";
import {agreementLink, couponsLink, rulesLink} from "../helpers/links";
import {browserDetect, yandexMetrikaHandler} from "../helpers/utils";

export const MainPage = () => {

    const playLinkContainer = document.querySelector('#link_1');
    const termsLinkContainer = document.querySelector('#link_2');
    const prizesLinkContainer = document.querySelector('#link_3');
    const sponsorsLinkContainer = document.querySelector('#link_4');
    const partnersLinkContainer = document.querySelector('#link_5');
    const winnersLinkContainer = document.querySelector('#link_6');

    const isShowFullLogoListCheck = useMemo(() => {
        if (typeof window !== 'undefined' && ['#sponsors', '#partners'].includes(window?.location?.hash)) {
            return {
                isShow: true,
                idx: window?.location?.hash === '#sponsors' ? 0 : 1,
            }
        } else {
            return {
                isShow: false,
                idx: 0,
            }
        }
    }, [])

    const [isShowFullLogoList, setIsShowFullLogoList] = useState(isShowFullLogoListCheck.isShow);

    const [chosenIdx, setChosenIdx] = useState(isShowFullLogoListCheck.idx);

    const onChangeChosenElement = (idx: number) => {
        setChosenIdx(idx);
    }

    const onChangeIsShowFullLogoList = () => {
        setIsShowFullLogoList(prevState => !prevState);
    }

    const isSafari = browserDetect().split(' ')[0] === 'Safari';

    const onChangeIsShowFullLogoListByValue = (value: boolean, linkContainer: Element | null) => () => {
        setIsShowFullLogoList(value);
        playLinkContainer?.classList.remove('selected_link');
        termsLinkContainer?.classList.remove('selected_link');
        prizesLinkContainer?.classList.remove('selected_link');
        sponsorsLinkContainer?.classList.remove('selected_link');
        partnersLinkContainer?.classList.remove('selected_link');
        winnersLinkContainer?.classList.remove('selected_link');
        linkContainer?.classList.add('selected_link');
    }

    const onClickSponsorsAndPartners = (chosenElement: number, linkContainer: Element | null) => () => {
        onChangeChosenElement(chosenElement);
        onChangeIsShowFullLogoListByValue(true, linkContainer)();
        yandexMetrikaHandler(chosenElement ? 'partnersClick': 'productAndSponsorsClick');
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && window?.location?.hash) {
            document.querySelector(window?.location?.hash)?.scrollIntoView();
        }
    }, [])

    return (
        <div>
            <div className={styles.first_screen}>
                <FirstScreen/>
            </div>

            <img src={"images/main_image.png"}
                 alt={'День рождения'}
                 className={styles.first_screen_image}
                 decoding={"async"}
            />

            <div className={styles.basic_part}>
                <div className={styles.menu_wrapper}>
                    <div className={styles.ad_title}>
                        Реклама www.magnit.ru
                    </div>
                    <div className={styles.menu}>
                        <a href={'#play'} id={'link_1'}
                           onClick={onChangeIsShowFullLogoListByValue(false, playLinkContainer)}
                        >
                            Игра
                        </a>
                        <a onClick={onChangeIsShowFullLogoListByValue(false, termsLinkContainer)}
                           href={'#terms'} id={'link_2'}>
                            Условия акции
                        </a>
                        <a onClick={onChangeIsShowFullLogoListByValue(false, prizesLinkContainer)}
                           href={'#prizes'} id={'link_3'}>
                            Призы
                        </a>
                        <a onClick={onClickSponsorsAndPartners(0, sponsorsLinkContainer)}
                           href={'#sponsors'} id={'link_4'}>
                            Товары-спонсоры
                        </a>
                        <a onClick={onClickSponsorsAndPartners(1, partnersLinkContainer)}
                           href={'#partners'} id={'link_5'}>
                            Партнеры акции
                        </a>
                        <div>
                            <a onClick={onChangeIsShowFullLogoListByValue(false, winnersLinkContainer)}
                               href={'#winners'} id={'link_6'}>
                                Победители
                            </a>
                        </div>
                    </div>
                </div>

                {
                    isShowFullLogoList ?
                        <FullLogoListPage onChangeIsShowFullLogoList={onChangeIsShowFullLogoList}
                                          chosenIdx={chosenIdx}
                                          onChangeChosenElement={onChangeChosenElement}/> :
                        <BasicPart onClickSponsorsAndPartners={onClickSponsorsAndPartners}
                                   isSafari={isSafari}/>
                }

                <div className={styles.rules_button_wrapper}>
                    <a className={styles.agreement_button}
                       target={'_blank'}
                       rel="noreferrer"
                       href={couponsLink}
                    >
                        <div>
                            Спонсорские купоны
                        </div>
                        <SimpleRedArrow/>
                    </a>
                    <a className={styles.rules_button}
                       target={'_blank'}
                       rel="noreferrer"
                       href={agreementLink}
                    >
                        <div>
                            Соглашение на обработку персональных данных
                        </div>
                        <SimpleRedArrow/>
                    </a>
                    <a className={styles.agreement_button}
                       target={'_blank'}
                       rel="noreferrer"
                       href={rulesLink}
                    >
                        <div>
                            Полные правила акции
                        </div>
                        <SimpleRedArrow/>
                    </a>
                </div>
            </div>
        </div>
    )
};