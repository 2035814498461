import axios from "axios";

const baseURL = process.env.REACT_APP_BUILD === 'development' ?
    'https://landingshare.promobot.winsolutions.ru/download/dev-winners.js' :
    'https://landingshare.promobot.winsolutions.ru/download/winners.js';

export const createRequest = axios.create(
    {
        baseURL
    }
)