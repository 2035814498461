import React from "react";
import styles from "./styles.module.scss";
import {ElementWrapper} from "../../components/ElementWrapper";
import {YellowBalloon} from "../../components/balloons/YellowBalloon";
import {BlueBalloon} from "../../components/balloons/BlueBalloon";
import {SmallPinkBalloon} from "../../components/balloons/SmallPinkBalloon";
import cc from "classnames";
import {Star} from "../../components/Star";

type IPrizesProps = {
    isSafari: boolean,
}

export const Prizes = ({
                              isSafari
                          }: IPrizesProps) => {

    return (
        <ElementWrapper>
            <div className={styles.title}>
                Какие еженедельные призы можно выиграть?
            </div>

            <div className={styles.description}>
                Выигрыш перечисляется по реквизитам банковской карты, оформленной на имя победителя в любом банке России
            </div>

            <div className={isSafari ? styles.safari : styles.prizes}>
                <img src={"/images/prizes.png"}
                     alt={'Призы'}
                     decoding={"async"}
                />
            </div>
            <div className={styles.fourth_yellow_balloon}>
                <YellowBalloon/>
            </div>
            <div className={styles.fourth_blue_balloon}>
                <BlueBalloon/>
            </div>
            <div className={styles.fifth_blue_balloon}>
                <BlueBalloon/>
            </div>
            <div className={styles.fourth_pink_balloon}>
                <SmallPinkBalloon/>
            </div>
            <div className={cc(styles.ninth_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.tenth_star, styles.big_screen)}>
                <Star/>
            </div>
        </ElementWrapper>
    )
};