import React from "react";

export const SmallPinkBalloon = () => {

    return (
        <svg width="113" height="145" viewBox="0 0 113 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M57.1675 134.706C77.7749 134.075 111.612 96.7234 111.151 58.7951C110.797 29.674 86.038 0.296651 55.2183 0.671296C24.3985 1.04594 0.360716 31.0164 0.714713 60.1376C1.13797 94.9567 33.8187 132.212 54.5996 134.594C54.5376 134.816 54.5789 135.066 54.7377 135.262L54.7642 135.295C55.0063 135.594 54.9012 136.05 54.5854 136.27C53.7595 136.845 52.6987 137.933 52.3582 139.535C51.7806 140.216 50.9213 141.835 52.1057 142.86C53.5862 144.142 55.9297 144.114 58.1196 143.51C60.3096 142.905 62.2014 141.871 61.7445 140.432C61.2875 138.993 60.5499 138.568 60.2552 138.427C60.1793 138.391 60.122 138.278 60.0384 138.113C59.8031 137.65 59.3603 136.777 57.7135 136.057C57.632 136.021 57.5532 135.976 57.4827 135.922C57.1116 135.637 56.9819 135.136 57.1675 134.706Z"
                  fill="#FF76C2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M57.1675 134.706C77.7749 134.075 111.612 96.7234 111.151 58.7951C110.797 29.674 86.038 0.296651 55.2183 0.671296C24.3985 1.04594 0.360716 31.0164 0.714713 60.1376C1.13797 94.9567 33.8187 132.212 54.5996 134.594C54.5376 134.816 54.5789 135.066 54.7377 135.262L54.7642 135.295C55.0063 135.594 54.9012 136.05 54.5854 136.27C53.7595 136.845 52.6987 137.933 52.3582 139.535C51.7806 140.216 50.9213 141.835 52.1057 142.86C53.5862 144.142 55.9297 144.114 58.1196 143.51C60.3096 142.905 62.2014 141.871 61.7445 140.432C61.2875 138.993 60.5499 138.568 60.2552 138.427C60.1793 138.391 60.122 138.278 60.0384 138.113C59.8031 137.65 59.3603 136.777 57.7135 136.057C57.632 136.021 57.5532 135.976 57.4827 135.922C57.1116 135.637 56.9819 135.136 57.1675 134.706Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M52.2796 111.16C80.4137 110.818 102.908 84.7831 102.521 53.0096C102.317 36.2278 95.7715 21.2015 85.4944 10.8083C76.753 4.40969 66.2947 0.536735 55.0231 0.673752C25.2966 1.03511 1.90271 29.1416 0.750786 57.3704C2.55566 87.6741 25.0734 111.491 52.2796 111.16Z"
                fill="#FF76C2"/>
            <path
                d="M16.1892 37.9398C18.5868 29.4986 25.9277 20.9789 30.3148 16.8868C30.6205 16.6016 31.0251 16.4471 31.4464 16.4448C33.0517 16.4359 33.8389 18.4873 32.7203 19.6204C26.0669 26.3599 23.6696 33.242 23.1332 37.528C22.939 39.0796 22.0854 40.5485 20.651 41.1992C18.1733 42.3232 15.4512 40.5378 16.1892 37.9398Z"
                fill="#FF76C2"/>
            <path
                d="M16.1892 37.9398C18.5868 29.4986 25.9277 20.9789 30.3148 16.8868C30.6205 16.6016 31.0251 16.4471 31.4464 16.4448C33.0517 16.4359 33.8389 18.4873 32.7203 19.6204C26.0669 26.3599 23.6696 33.242 23.1332 37.528C22.939 39.0796 22.0854 40.5485 20.651 41.1992C18.1733 42.3232 15.4512 40.5378 16.1892 37.9398Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};