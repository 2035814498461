import React from "react";

export const Plus = () => {

    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1426_735)">
                <rect x="10.0781" width="3.87097" height="24" fill="#E41014"/>
                <rect y="13.9336" width="3.87097" height="24" transform="rotate(-90 0 13.9336)" fill="#E41014"/>
            </g>
            <defs>
                <clipPath id="clip0_1426_735">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
};