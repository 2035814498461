import React from "react";
import styles from "./Images.module.scss";

type IImagesProps = {
    path: string;
    number: number;
}

export const Images = ({
                           path,
                           number
                       }: IImagesProps) => {

    const arr = Array.from(Array(number).keys());

    return (
        <div className={styles.images}>
            {
                arr.map((el) => {
                    return (
                        <div className={styles.image}
                             key={el}>
                            <img src={`${path}${el}.png`}
                                 alt={'Партнер акции'}
                                 decoding={"async"}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
};