import React from "react";
import styles from "./styles.module.scss";
import {ElementWrapper} from "../../components/ElementWrapper";
import cc from "classnames";
import {ImagesWithTitles} from "../../components/ImagesWithTitles";
import {cakeTitles} from "../../helpers/sponsors_titles";
import {Arrow} from "../../components/Arrow";

type ISponsorsProps = {
    onClickSponsorsAndPartners: Function
}

export const Sponsors = ({
                             onClickSponsorsAndPartners
                         }: ISponsorsProps) => {

    return (
        <ElementWrapper>
            <div className={styles.title}>
                Товары-спонсоры
            </div>

            <div className={cc(styles.description, styles.sponsors_description)}>
                Получайте больше попыток в игре за покупку этих товаров
            </div>

            <div className={styles.sponsors}>
                <ImagesWithTitles path={'images/sponsors/cake/'}
                                  number={4}
                                  titles={cakeTitles}
                                  isRow={true}
                />
            </div>

            <a className={styles.open_list}
               onClick={onClickSponsorsAndPartners(0)}
               href={'#sponsors_partners'}>
                <div>
                    <div>
                        Открыть полный список
                    </div>
                    <Arrow/>
                </div>
            </a>

        </ElementWrapper>
    )
};