import React from "react";

export const HalfYellowBalloon = () => {

    return (
        <svg width="134" height="289" viewBox="0 0 134 289" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M33.3146 254.665C69.6948 247.642 119.027 171.68 107.344 104.521C98.3732 52.9568 46.0302 7.92947 -8.54195 17.4234C-63.1141 26.9173 -97.1752 86.9763 -88.2046 138.541C-77.4787 200.194 -8.82441 256.93 28.7266 255.201C28.6804 255.614 28.8253 256.045 29.1631 256.348L29.2196 256.398C29.7347 256.86 29.6788 257.698 29.1815 258.179C27.8811 259.436 26.3108 261.67 26.1655 264.609C25.3358 265.983 24.275 269.101 26.6702 270.582C29.6641 272.433 33.8137 271.711 37.5259 270.011C41.2382 268.311 44.2982 265.934 43.0753 263.512C41.8523 261.09 40.422 260.548 39.8589 260.383C39.7139 260.34 39.5797 260.156 39.3842 259.887C38.834 259.132 37.7984 257.711 34.6704 256.905C34.5156 256.866 34.363 256.808 34.2223 256.732C33.4822 256.332 33.1086 255.48 33.3146 254.665Z"
                  fill="#FFD43B"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M33.3146 254.665C69.6948 247.642 119.027 171.68 107.344 104.521C98.3732 52.9568 46.0302 7.92947 -8.54195 17.4234C-63.1141 26.9173 -97.1752 86.9763 -88.2046 138.541C-77.4787 200.194 -8.82441 256.93 28.7266 255.201C28.6804 255.614 28.8253 256.045 29.1631 256.348L29.2196 256.398C29.7347 256.86 29.6788 257.698 29.1815 258.179C27.8811 259.436 26.3108 261.67 26.1655 264.609C25.3358 265.983 24.275 269.101 26.6702 270.582C29.6641 272.433 33.8137 271.711 37.5259 270.011C41.2382 268.311 44.2982 265.934 43.0753 263.512C41.8523 261.09 40.422 260.548 39.8589 260.383C39.7139 260.34 39.5797 260.156 39.3842 259.887C38.834 259.132 37.7984 257.711 34.6704 256.905C34.5156 256.866 34.363 256.808 34.2223 256.732C33.4822 256.332 33.1086 255.48 33.3146 254.665Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M17.8966 214.291C67.7132 205.624 100.163 152.99 90.3754 96.7289C85.2058 67.0135 69.2874 42.2296 48.0767 26.7344C30.7349 17.8865 11.0705 14.0113 -8.88782 17.4835C-61.5241 26.6406 -94.977 83.2081 -88.9337 133.621C-77.0502 186.868 -30.2771 222.671 17.8966 214.291Z"
                fill="#FFD43B"/>
        </svg>
    )
};