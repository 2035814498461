import React from "react";

export const SmallBlueBalloon = () => {

    return (
        <svg width="123" height="156" viewBox="0 0 123 156" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M58.9083 143.654C80.6368 144.168 118.392 106.784 120.075 66.8322C121.367 36.1574 96.9833 3.81768 64.5192 2.45015C32.0552 1.08261 5.03829 31.2571 3.74612 61.9319C2.20114 98.6084 34.4726 139.694 56.2117 143.389C56.1337 143.62 56.163 143.885 56.3189 144.1L56.3449 144.136C56.5826 144.465 56.446 144.938 56.101 145.152C55.1986 145.711 54.0199 146.795 53.5699 148.462C52.9228 149.146 51.9258 150.801 53.1139 151.948C54.599 153.382 57.0676 153.486 59.4074 152.975C61.7473 152.464 63.7978 151.484 63.3991 149.943C63.0003 148.402 62.2481 147.913 61.946 147.748C61.8682 147.705 61.8143 147.583 61.7357 147.405C61.5146 146.903 61.0983 145.959 59.406 145.107C59.3222 145.065 59.2418 145.013 59.1707 144.952C58.7965 144.63 58.6886 144.096 58.9083 143.654Z"
                  fill="#67C7FD"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M58.9083 143.654C80.6368 144.168 118.392 106.784 120.075 66.8322C121.367 36.1574 96.9833 3.81768 64.5192 2.45015C32.0552 1.08261 5.03829 31.2571 3.74612 61.9319C2.20114 98.6084 34.4726 139.694 56.2117 143.389C56.1337 143.62 56.163 143.885 56.3189 144.1L56.3449 144.136C56.5826 144.465 56.446 144.938 56.101 145.152C55.1986 145.711 54.0199 146.795 53.5699 148.462C52.9228 149.146 51.9258 150.801 53.1139 151.948C54.599 153.382 57.0676 153.486 59.4074 152.975C61.7473 152.464 63.7978 151.484 63.3991 149.943C63.0003 148.402 62.2481 147.913 61.946 147.748C61.8682 147.705 61.8143 147.583 61.7357 147.405C61.5146 146.903 61.0983 145.959 59.406 145.107C59.3222 145.065 59.2418 145.013 59.1707 144.952C58.7965 144.63 58.6886 144.096 58.9083 143.654Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M55.1117 118.589C84.7467 119.837 109.914 93.7174 111.323 60.2487C112.068 42.5715 106.036 26.3798 95.8124 14.8517C86.9765 7.61649 76.1889 2.94171 64.316 2.44157C33.0035 1.12254 6.77093 29.3717 3.94456 59.021C4.11207 91.0237 26.4539 117.382 55.1117 118.589Z"
                fill="#67C7FD"/>
            <path
                d="M21.3015 39.45C24.3079 30.7014 32.5224 22.1527 37.3745 18.0959C37.7126 17.8133 38.1473 17.6737 38.5909 17.6954C40.2813 17.7778 40.9927 19.9823 39.7503 21.1111C32.3613 27.8251 29.4444 34.9325 28.6347 39.4135C28.3416 41.0357 27.359 42.5332 25.8119 43.1361C23.1394 44.1776 20.3761 42.1426 21.3015 39.45Z"
                fill="#67C7FD"/>
            <path
                d="M21.3015 39.45C24.3079 30.7014 32.5224 22.1527 37.3745 18.0959C37.7126 17.8133 38.1473 17.6737 38.5909 17.6954C40.2813 17.7778 40.9927 19.9823 39.7503 21.1111C32.3613 27.8251 29.4444 34.9325 28.6347 39.4135C28.3416 41.0357 27.359 42.5332 25.8119 43.1361C23.1394 44.1776 20.3761 42.1426 21.3015 39.45Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};