import React from 'react';
import { Provider } from 'react-redux';
import './App.scss';
import {MainPage} from "./modules/MainPage";
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';
import MainPageReducer from "./modules/MainPage.reducer";

let reducers = combineReducers({
    mainPage: MainPageReducer
});

export const store = createStore(reducers, {}, applyMiddleware(thunkMiddleware));

function App() {
    return (
        <Provider store={store}>
            <MainPage/>
        </Provider>
    );
}

export default App;