import React from "react";
import styles from "./styles.module.scss";
import {ElementWrapper} from "../../components/ElementWrapper";
import {appStoreLInk, googleStoreLInk, huaweiStoreLInk} from "../../helpers/links";
import {YellowBalloon} from "../../components/balloons/YellowBalloon";
import {SmallBlueBalloon} from "../../components/balloons/SmallBlueBalloon";
import {BlueBalloon} from "../../components/balloons/BlueBalloon";
import {MediumPinkBalloon} from "../../components/balloons/MediumPinkBalloon";
import {SmallPinkBalloon} from "../../components/balloons/SmallPinkBalloon";
import {Star} from "../../components/Star";
import cc from "classnames";
import {yandexMetrikaHandler} from "../../helpers/utils";

type IGameRulesProps = {
    isSafari: boolean,
}

export const GameRules = ({
                              isSafari
                          }: IGameRulesProps) => {


    return (
        <ElementWrapper>
            <div className={styles.title}>
                Как участвовать в игре?
            </div>

            <div className={styles.sub_title}>
                <span className={styles.number}>
                    {'1. '}
                </span>
                Скачайте или обновите приложение Магнит
            </div>
            <div className={styles.images}>
                <img src={"images/qr.png"}
                     alt={'QR-код'}
                     className={styles.qr_code}
                     decoding={"async"}
                />
                <div className={styles.qr_code_wrapper}>
                    <div className={styles.qr_code_text}>
                        Отсканируйте QR-код телефоном и скачайте приложение
                    </div>
                </div>
                <div className={styles.store_links}>
                    <a target={'_blank'}
                       href={googleStoreLInk}
                       rel="noreferrer"
                       onClick={() => yandexMetrikaHandler('googlePlayClick')}
                    >
                        <img src={"images/google_store.png"}
                             alt={'Приложение'}
                             decoding={"async"}
                        />
                    </a>
                    <a target={'_blank'}
                       href={appStoreLInk}
                       rel="noreferrer"
                       onClick={() => yandexMetrikaHandler('appStoreClick')}
                    >
                        <img src={"images/app_store.png"}
                             alt={'Приложение'}
                             decoding={"async"}
                        />
                    </a>
                    <a target={'_blank'}
                       href={huaweiStoreLInk}
                       rel="noreferrer"
                       onClick={() => yandexMetrikaHandler('appGalleryClick')}
                    >
                        <img src={"images/huawei_store.png"}
                             alt={'Приложение'}
                             decoding={"async"}
                        />
                    </a>
                </div>
            </div>

            <div className={styles.sub_title}>
                <span className={styles.number}>
                    {'2. '}
                </span>
                Нажмите на картинку с игрой
            </div>
            <div className={styles.description}>
                На главном экране приложения в разделе Акции
            </div>
            <div className={isSafari ? styles.safari : styles.game_images}>
                <img src={"images/game_image.png"}
                     alt={'Приложение'}
                     decoding={"async"}
                />
            </div>

            <div className={styles.sub_title}>
                <span className={styles.number}>
                    {'3. '}
                </span>
                Играйте
            </div>
            <div className={styles.description}>
                Ежедневно доступна 1 игра. Дополнительно можно получить ещё 4 попытки. Для этого покупайте товары по
                условиям акции, пользуясь картой из приложения Магнит
            </div>
            <div className={isSafari ? styles.safari : styles.game_images}>
                <img src={"images/game.png"}
                     alt={'Приложение'}
                     decoding={"async"}
                />
            </div>

            <div className={styles.first_yellow_balloon}>
                <YellowBalloon/>
            </div>
            <div className={styles.second_yellow_balloon}>
                <YellowBalloon/>
            </div>
            <div className={styles.first_blue_balloon}>
                <SmallBlueBalloon/>
            </div>
            <div className={styles.second_blue_balloon}>
                <BlueBalloon/>
            </div>
            <div className={styles.first_pink_balloon}>
                <MediumPinkBalloon/>
            </div>
            <div className={styles.second_pink_balloon}>
                <SmallPinkBalloon/>
            </div>
            <img src="images/soap_bubble.png"
                 alt={'Пузырь'}
                 className={styles.first_soap_bubble}
                 decoding={"async"}
            />
            <img src="images/soap_bubble.png"
                 alt={'Пузырь'}
                 className={styles.second_soap_bubble}
                 decoding={"async"}
            />
            <div className={cc(styles.first_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.second_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.third_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.fourth_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.fifth_star, styles.big_screen)}>
                <Star/>
            </div>
            <div className={cc(styles.sixth_star, styles.big_screen)}>
                <Star/>
            </div>

        </ElementWrapper>
    )
};