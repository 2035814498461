import React from "react";

export const YellowBalloon = () => {

    return (
        <svg width="149" height="178" viewBox="0 0 149 178" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M86.2487 157.049C108.72 152.711 139.193 105.79 131.976 64.3063C126.435 32.4555 94.103 4.64251 60.3943 10.5068C26.6856 16.3711 5.64638 53.469 11.1875 85.3198C17.8127 123.403 60.2198 158.448 83.4147 157.38C83.386 157.635 83.4755 157.901 83.6843 158.088L83.7191 158.119C84.0374 158.405 84.0028 158.922 83.6957 159.219C82.8924 159.996 81.9224 161.376 81.8327 163.191C81.3201 164.04 80.665 165.966 82.1444 166.881C83.9937 168.024 86.5569 167.578 88.8499 166.528C91.1429 165.478 93.0331 164.01 92.2777 162.514C91.5222 161.017 90.6388 160.683 90.2909 160.58C90.2014 160.554 90.1185 160.44 89.9977 160.275C89.6579 159.808 89.0182 158.93 87.0861 158.433C86.9904 158.408 86.8962 158.373 86.8093 158.326C86.3521 158.078 86.1213 157.552 86.2487 157.049Z"
                  fill="#FFD43B"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M86.2487 157.049C108.72 152.711 139.193 105.79 131.976 64.3063C126.435 32.4555 94.103 4.64251 60.3943 10.5068C26.6856 16.3711 5.64638 53.469 11.1875 85.3198C17.8127 123.403 60.2198 158.448 83.4147 157.38C83.386 157.635 83.4755 157.901 83.6843 158.088L83.7191 158.119C84.0374 158.405 84.0028 158.922 83.6957 159.219C82.8924 159.996 81.9224 161.376 81.8327 163.191C81.3201 164.04 80.665 165.966 82.1444 166.881C83.9937 168.024 86.5569 167.578 88.8499 166.528C91.1429 165.478 93.0331 164.01 92.2777 162.514C91.5222 161.017 90.6388 160.683 90.2909 160.58C90.2014 160.554 90.1185 160.44 89.9977 160.275C89.6579 159.808 89.0182 158.93 87.0861 158.433C86.9904 158.408 86.8962 158.373 86.8093 158.326C86.3521 158.078 86.1213 157.552 86.2487 157.049Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M76.7254 132.11C107.497 126.756 127.541 94.2448 121.495 59.493C118.302 41.1381 108.469 25.8294 95.3674 16.2581C84.6555 10.7928 72.509 8.39917 60.1809 10.5439C27.668 16.2001 7.00451 51.1414 10.7374 82.2807C18.0777 115.171 46.969 137.286 76.7254 132.11Z"
                fill="#FFD43B"/>
            <path
                d="M24.2178 58.2552C25.3516 48.5804 31.8887 37.9447 35.9725 32.6838C36.257 32.3173 36.6731 32.0764 37.1343 31.9992C38.892 31.7054 40.1177 33.8143 39.0923 35.254C32.9935 43.817 31.5842 51.7833 31.7548 56.5751C31.8166 58.3099 31.141 60.0707 29.6842 61.0376C27.1678 62.7078 23.8688 61.2328 24.2178 58.2552Z"
                fill="#FFD43B"/>
            <path
                d="M24.2178 58.2552C25.3516 48.5804 31.8887 37.9447 35.9725 32.6838C36.257 32.3173 36.6731 32.0764 37.1343 31.9992C38.892 31.7054 40.1177 33.8143 39.0923 35.254C32.9935 43.817 31.5842 51.7833 31.7548 56.5751C31.8166 58.3099 31.141 60.0707 29.6842 61.0376C27.1678 62.7078 23.8688 61.2328 24.2178 58.2552Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};