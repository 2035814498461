import React from "react";

export const Star = () => {

    return (
        <svg width="37" height="44" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8995 2.47599C16.6719 -0.400426 20.7532 -0.400427 21.5255 2.47599L24.241 12.5886C24.4548 13.385 24.9962 14.0534 25.7308 14.428L35.3365 19.3262C37.4559 20.4069 37.4559 23.435 35.3365 24.5157L25.7309 29.4139C24.9962 29.7885 24.4548 30.4569 24.241 31.2533L21.5255 41.3659C20.7532 44.2423 16.6719 44.2423 15.8995 41.3659L13.1841 31.2533C12.9703 30.4569 12.4288 29.7885 11.6942 29.4139L2.08856 24.5157C-0.0307972 23.435 -0.0307994 20.4069 2.08856 19.3262L11.6942 14.428C12.4288 14.0534 12.9703 13.385 13.1841 12.5886L15.8995 2.47599Z"
                fill="url(#paint0_linear_1074_2517)"/>
            <defs>
                <linearGradient id="paint0_linear_1074_2517" x1="4.42797" y1="4.86075" x2="41.8882" y2="30.9712"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFEEC9"/>
                    <stop offset="1" stopColor="#FFCA61"/>
                </linearGradient>
            </defs>
        </svg>
    )
};