import React from "react";
import styles from "../MainPage.module.scss";
import {GameDescription} from "./GameDescription";
import {GameRules} from "./GameRules";
import {ActionTerms} from "./ActionTerms";
import {Prizes} from "./Prizes";
import {Sponsors} from "./Sponsors";
import {Partners} from "./Partners";
import {Winners} from "./Winners";

type IBasicPartProps = {
    onClickSponsorsAndPartners: Function,
    isSafari: boolean,
}

export const BasicPart = ({
                              onClickSponsorsAndPartners,
                              isSafari
                          }: IBasicPartProps) => {

    return (
        <div>
            <div className={styles.game_text}>
                <GameDescription/>
            </div>

            <div className={styles.elem_wrapper}
                 id={'play'}>
                <GameRules isSafari={isSafari}/>
            </div>

            <div className={styles.elem_wrapper}
                 id={'terms'}>
                <ActionTerms/>
            </div>

            <div className={styles.elem_wrapper}
                 id={'prizes'}>
                <Prizes isSafari={isSafari}/>
            </div>

            <div className={styles.elem_wrapper}>
                <Sponsors onClickSponsorsAndPartners={onClickSponsorsAndPartners}/>
            </div>

            <div className={styles.elem_wrapper}>
                <Partners onClickSponsorsAndPartners={onClickSponsorsAndPartners}/>
            </div>

            <div className={styles.elem_wrapper}
                 id={'winners'}>
                <Winners/>
            </div>
        </div>
    )
};