import styles from "./MultiToggle.module.scss";
import React from "react";
import {Circle} from "./Circle";
import cc from "classnames";

type IMultiToggleProps = {
    tabTitles: Array<string>;
    chosenIdx: number;
    onChangeChosenElement: (idx: number) => void;
}

export const MultiToggle = ({
                                tabTitles,
                                chosenIdx,
                                onChangeChosenElement
                            }: IMultiToggleProps) => {

    const onClickToggle = (e: React.SyntheticEvent<HTMLSpanElement>) => {
        const idx = Number(e.currentTarget.dataset.chosenIdx);
        onChangeChosenElement(idx);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                {
                    tabTitles.map((el: string, idx: number) => {
                        const isLastIdx = tabTitles.length - 1 === idx;
                        const isChosenIdx = chosenIdx === idx;

                        return (
                            <span data-chosen-idx={idx}
                                  onClick={onClickToggle}
                                  key={el + idx}
                                  className={styles.multi_toggle}
                            >
                                <span className={cc(styles.pointer, isChosenIdx && styles.chosen_tab)}>
                                    {el}
                                </span>
                                {
                                    !isLastIdx &&
                                    <div className={styles.circle}>
                                        <Circle/>
                                    </div>
                                }
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
};