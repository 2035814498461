import React from "react";

export const MediumPinkBalloon = () => {

    return (
        <svg width="133" height="168" viewBox="0 0 133 168" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M63.0845 154.123C86.3274 154.851 127.026 115.162 129.153 72.4316C130.786 39.6235 104.962 4.82455 70.2404 3.09634C35.5186 1.36814 6.36718 33.4308 4.73422 66.239C2.78176 105.466 36.9733 149.687 60.2015 153.817C60.1161 154.064 60.1452 154.348 60.3103 154.58L60.3378 154.619C60.5894 154.972 60.4394 155.478 60.0685 155.703C59.0986 156.294 57.8285 157.444 57.3335 159.223C56.6357 159.95 55.5554 161.712 56.8172 162.95C58.3944 164.497 61.0346 164.628 63.5422 164.1C66.0498 163.572 68.2516 162.54 67.8376 160.888C67.4235 159.236 66.6228 158.707 66.3008 158.528C66.218 158.482 66.1613 158.35 66.0787 158.159C65.8462 157.621 65.4086 156.608 63.6049 155.682C63.5156 155.636 63.43 155.58 63.3544 155.514C62.9566 155.167 62.8455 154.594 63.0845 154.123Z"
                  fill="#FF76C2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M63.0845 154.123C86.3274 154.851 127.026 115.162 129.153 72.4316C130.786 39.6235 104.962 4.82455 70.2404 3.09634C35.5186 1.36814 6.36718 33.4308 4.73422 66.239C2.78176 105.466 36.9733 149.687 60.2015 153.817C60.1161 154.064 60.1452 154.348 60.3103 154.58L60.3378 154.619C60.5894 154.972 60.4394 155.478 60.0685 155.703C59.0986 156.294 57.8285 157.444 57.3335 159.223C56.6357 159.95 55.5554 161.712 56.8172 162.95C58.3944 164.497 61.0346 164.628 63.5422 164.1C66.0498 163.572 68.2516 162.54 67.8376 160.888C67.4235 159.236 66.6228 158.707 66.3008 158.528C66.218 158.482 66.1613 158.35 66.0787 158.159C65.8462 157.621 65.4086 156.608 63.6049 155.682C63.5156 155.636 63.43 155.58 63.3544 155.514C62.9566 155.167 62.8455 154.594 63.0845 154.123Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M59.2242 127.275C90.9202 128.852 118.059 101.113 119.841 65.3164C120.782 46.4099 114.461 29.0373 103.617 16.6201C94.2222 8.80703 82.7188 3.71745 70.0202 3.0854C36.5301 1.4185 8.23348 31.4277 4.96747 63.1262C4.88536 97.3668 28.5734 125.749 59.2242 127.275Z"
                fill="#FF76C2"/>
            <path
                d="M23.7031 42.3292C26.9911 32.9937 35.8495 23.9146 41.0738 19.6139C41.4378 19.3143 41.9041 19.1685 42.3785 19.1953C44.1864 19.2973 44.9295 21.6616 43.5911 22.8592C35.6308 29.9821 32.452 37.5625 31.5492 42.35C31.2223 44.0832 30.1588 45.6773 28.4986 46.3097C25.6309 47.4022 22.6911 45.2024 23.7031 42.3292Z"
                fill="#FF76C2"/>
            <path
                d="M23.7031 42.3292C26.9911 32.9937 35.8495 23.9146 41.0738 19.6139C41.4378 19.3143 41.9041 19.1685 42.3785 19.1953C44.1864 19.2973 44.9295 21.6616 43.5911 22.8592C35.6308 29.9821 32.452 37.5625 31.5492 42.35C31.2223 44.0832 30.1588 45.6773 28.4986 46.3097C25.6309 47.4022 22.6911 45.2024 23.7031 42.3292Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};