import React from "react";

export const BlueBalloon = () => {

    return (
        <svg width="162" height="196" viewBox="0 0 162 196" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M69.6365 174.013C94.8828 177.365 143.601 138.601 150.625 92.2742C156.017 56.7048 131.711 15.9422 94.0673 10.235C56.4232 4.52784 21.1272 36.2543 15.7346 71.8237C9.28689 114.352 41.6733 166.303 66.5288 173.362C66.4088 173.621 66.4094 173.933 66.5636 174.204L66.5893 174.25C66.8245 174.663 66.6054 175.197 66.1764 175.402C65.0545 175.938 63.5439 177.052 62.8085 178.936C61.9681 179.651 60.5969 181.453 61.8355 182.94C63.3838 184.799 66.2462 185.233 69.0367 184.934C71.8272 184.635 74.3401 183.753 74.0709 181.907C73.8017 180.062 72.9874 179.397 72.6563 179.166C72.5711 179.107 72.5238 178.957 72.4549 178.74C72.2608 178.128 71.8956 176.975 70.0321 175.768C69.9398 175.709 69.8528 175.638 69.7777 175.558C69.3825 175.136 69.3246 174.499 69.6365 174.013Z"
                  fill="#67C7FD"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M69.6365 174.013C94.8828 177.365 143.601 138.601 150.625 92.2742C156.017 56.7048 131.711 15.9422 94.0673 10.235C56.4232 4.52784 21.1272 36.2543 15.7346 71.8237C9.28689 114.352 41.6733 166.303 66.5288 173.362C66.4088 173.621 66.4094 173.933 66.5636 174.204L66.5893 174.25C66.8245 174.663 66.6054 175.197 66.1764 175.402C65.0545 175.938 63.5439 177.052 62.8085 178.936C61.9681 179.651 60.5969 181.453 61.8355 182.94C63.3838 184.799 66.2462 185.233 69.0367 184.934C71.8272 184.635 74.3401 183.753 74.0709 181.907C73.8017 180.062 72.9874 179.397 72.6563 179.166C72.5711 179.107 72.5238 178.957 72.4549 178.74C72.2608 178.128 71.8956 176.975 70.0321 175.768C69.9398 175.709 69.8528 175.638 69.7777 175.558C69.3825 175.136 69.3246 174.499 69.6365 174.013Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M68.3904 144.333C102.754 149.543 135.381 122.305 141.265 83.4962C144.373 62.9983 139.398 43.3722 128.949 28.6475C119.573 19.0994 107.599 12.2866 93.8316 10.1994C57.5228 4.69468 23.3845 34.2777 16.3347 68.4581C12.4741 105.759 35.1599 139.295 68.3904 144.333Z"
                fill="#67C7FD"/>
            <path
                d="M39.0341 47.8593C43.645 38.0491 54.2974 29.1317 60.4638 25.0208C60.8934 24.7344 61.4175 24.627 61.9315 24.7084C63.8902 25.0186 64.4395 27.6768 62.8493 28.8343C53.3909 35.719 49.0923 43.6289 47.5812 48.7462C47.0342 50.5988 45.6998 52.2186 43.8211 52.7249C40.576 53.5995 37.615 50.8787 39.0341 47.8593Z"
                fill="#67C7FD"/>
            <path
                d="M39.0341 47.8593C43.645 38.0491 54.2974 29.1317 60.4638 25.0208C60.8934 24.7344 61.4175 24.627 61.9315 24.7084C63.8902 25.0186 64.4395 27.6768 62.8493 28.8343C53.3909 35.719 49.0923 43.6289 47.5812 48.7462C47.0342 50.5988 45.6998 52.2186 43.8211 52.7249C40.576 53.5995 37.615 50.8787 39.0341 47.8593Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};