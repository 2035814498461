import React from "react";
import {SponsorElem} from "../../../components/SponsorElem";
import {allSponsors} from "../../../helpers/all_sponsors";

export const AllSponsors = () => {

    return (
        <div>
            {
                allSponsors.map((el) => {
                        return <SponsorElem key={el.title}
                                            title={el.title}
                                            path={el.path}
                                            number={el.number}
                                            titles={el.titles}
                                />
                    }
                )
            }
        </div>
    )
};