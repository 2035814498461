import React from "react";
import styles from "./FullLogoListPage.module.scss";
import mainPageStyles from "../../MainPage.module.scss";
import {SmallArrow} from "../../../components/SmallArrow";
import {ElementWrapper} from "../../../components/ElementWrapper";
import {MultiToggle} from "../../../components/MultiToggle";
import {Images} from "../../../components/Images";
import {AllSponsors} from "../AllSponsors/AllSponsors";
import {GameDescription} from "../GameDescription";

const tabTitles = ['Товары-спонсоры', 'При участии'];

type IFullLogoListPageProps = {
    onChangeIsShowFullLogoList: VoidFunction;
    chosenIdx: number;
    onChangeChosenElement: (idx: number) => void;
}

export const FullLogoListPage = ({
                                     onChangeIsShowFullLogoList,
                                     chosenIdx,
                                     onChangeChosenElement
                                 }: IFullLogoListPageProps) => {

    return (
        <div className={styles.wrapper}>
            <div className={mainPageStyles.game_text}>
                <GameDescription/>
            </div>

            <div className={styles.elem_wrapper}
                 id={'sponsors'}>
                <div className={styles.arrow}
                     id={'partners'}
                     onClick={onChangeIsShowFullLogoList}>
                    <SmallArrow/>
                    <div className={styles.arrow_text}>
                        Вернуться назад
                    </div>
                </div>

                <ElementWrapper>
                    <MultiToggle tabTitles={tabTitles}
                                 onChangeChosenElement={onChangeChosenElement}
                                 chosenIdx={chosenIdx}
                    />
                    {
                        chosenIdx === 0 &&
                        <AllSponsors/>
                    }
                    {
                        chosenIdx === 1 &&
                        <Images number={66}
                                path={'/images/brands/'}/>
                    }
                </ElementWrapper>
            </div>
        </div>
    )
};