import React from "react";
import styles from "./ImagesWithTitles.module.scss";
import cc from "classnames";

type IImagesWithTitlesProps = {
    path: string;
    number: number;
    titles: Array<String>;
    isRow?: boolean;
}

export const ImagesWithTitles = ({
                                     path,
                                     number,
                                     titles,
                                     isRow,
                                 }: IImagesWithTitlesProps) => {

    const arr = Array.from(Array(number).keys());

    return (
        <div className={cc(styles.wrapper, isRow && styles.row)}>
            {
                arr.map((el) => {
                    return (
                        <div className={cc(styles.image, isRow && styles.row_images)}
                             key={el}>
                            <img src={`${path}${el}.png`}
                                 alt={'Товар-спонсор'}
                                 loading='lazy'
                            />
                            <div className={styles.title}>
                                {titles[Number(el)]}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};