import React from "react";

export const SmallYellowBalloon = () => {

    return (
        <svg width="103" height="130" viewBox="0 0 103 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M54.9451 118.107C72.4806 116.609 99.5811 83.1939 97.4198 50.8867C95.7604 26.0814 73.287 2.19551 47.0348 3.95169C20.7826 5.70786 1.69089 32.3743 3.35028 57.1797C5.33434 86.8385 34.9272 117.07 52.7511 118.131C52.7086 118.324 52.7555 118.534 52.9 118.694L52.9241 118.721C53.1444 118.965 53.0761 119.358 52.8171 119.56C52.14 120.089 51.2865 121.066 51.071 122.447C50.6104 123.055 49.9534 124.474 51.0108 125.293C52.3325 126.317 54.3286 126.184 56.1671 125.566C58.0056 124.949 59.5699 123.979 59.1133 122.774C58.6567 121.569 58.0082 121.241 57.7505 121.135C57.6841 121.108 57.6299 121.014 57.551 120.877C57.3289 120.493 56.9108 119.77 55.4735 119.233C55.4024 119.207 55.3331 119.172 55.2705 119.129C54.9409 118.903 54.807 118.482 54.9451 118.107Z"
                  fill="#FFD43B"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M54.9451 118.107C72.4806 116.609 99.5811 83.1939 97.4198 50.8867C95.7604 26.0814 73.287 2.19551 47.0348 3.95169C20.7826 5.70786 1.69089 32.3743 3.35028 57.1797C5.33434 86.8385 34.9272 117.07 52.7511 118.131C52.7086 118.324 52.7555 118.534 52.9 118.694L52.9241 118.721C53.1444 118.965 53.0761 119.358 52.8171 119.56C52.14 120.089 51.2865 121.066 51.071 122.447C50.6104 123.055 49.9534 124.474 51.0108 125.293C52.3325 126.317 54.3286 126.184 56.1671 125.566C58.0056 124.949 59.5699 123.979 59.1133 122.774C58.6567 121.569 58.0082 121.241 57.7505 121.135C57.6841 121.108 57.6299 121.014 57.551 120.877C57.3289 120.493 56.9108 119.77 55.4735 119.233C55.4024 119.207 55.3331 119.172 55.2705 119.129C54.9409 118.903 54.807 118.482 54.9451 118.107Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M49.681 98.265C73.6455 96.6619 91.6049 73.4221 89.7944 46.3574C88.8381 32.0627 82.558 19.56 73.3137 11.1803C65.5645 6.13394 56.4696 3.32035 46.8685 3.96262C21.5476 5.65651 2.91777 30.7041 3.25196 54.819C6.20316 80.5647 26.5068 99.8153 49.681 98.265Z"
                fill="#FFD43B"/>
            <path
                d="M15.5054 37.5374C17.1555 30.2307 23.0154 22.6266 26.5641 18.934C26.8113 18.6768 27.149 18.5262 27.5079 18.5046C28.8759 18.4221 29.6425 20.134 28.7418 21.152C23.3849 27.2067 21.6624 33.1845 21.405 36.8627C21.3118 38.1943 20.6527 39.4861 19.4604 40.1076C17.4009 41.1812 14.9975 39.7863 15.5054 37.5374Z"
                fill="#FFD43B"/>
            <path
                d="M15.5054 37.5374C17.1555 30.2307 23.0154 22.6266 26.5641 18.934C26.8113 18.6768 27.149 18.5262 27.5079 18.5046C28.8759 18.4221 29.6425 20.134 28.7418 21.152C23.3849 27.2067 21.6624 33.1845 21.405 36.8627C21.3118 38.1943 20.6527 39.4861 19.4604 40.1076C17.4009 41.1812 14.9975 39.7863 15.5054 37.5374Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};