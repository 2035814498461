import {IWeek, IWinners} from "../types";

export const setOpenComic = (winners: Array<IWeek>) => ({
    type: 'SET_WINNERS',
    payload: winners
});

export const setShownWinners = (winners: Array<IWinners>) => ({
    type: 'SET_SHOWN_WINNERS',
    payload: winners
});

export const setCurrentIndex = (currentIndex: number) => ({
    type: 'SET_CURRENT_INDEX',
    payload: currentIndex
});
