import React from "react";

export const Loupe = () => {

    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 11.8052H11.71L11.43 11.5352C12.41 10.3952 13 8.91518 13 7.30518C13 3.71518 10.09 0.805176 6.5 0.805176C2.91 0.805176 0 3.71518 0 7.30518C0 10.8952 2.91 13.8052 6.5 13.8052C8.11 13.8052 9.59 13.2152 10.73 12.2352L11 12.5152V13.3052L16 18.2952L17.49 16.8052L12.5 11.8052V11.8052ZM6.5 11.8052C4.01 11.8052 2 9.79518 2 7.30518C2 4.81518 4.01 2.80518 6.5 2.80518C8.99 2.80518 11 4.81518 11 7.30518C11 9.79518 8.99 11.8052 6.5 11.8052Z"
                fill="#C4C4C4"/>
        </svg>
    )
};