import React from "react";
import styles from "./GameDescription.module.scss";
import cc from "classnames";
import {commonAppLInk} from "../../helpers/links";
import {yandexMetrikaHandler} from "../../helpers/utils";

export const GameDescription = () => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Играйте и участвуйте в розыгрышах призов
            </div>
            <div className={styles.description}>
                Покупайте во всех магазинах семьи Магнит,
                <span className={styles.selected_text}> сканируйте карту из приложения</span>
                , выигрывайте скидочные купоны и денежные призы — до 1 миллиона рублей каждую неделю
            </div>
            <div className={styles.time}>
                Акция проходит с 12 октября по 8 ноября
            </div>
            <a className={cc(styles.red_button, styles.download_app_button)}
               target={'_blank'}
               href={commonAppLInk}
               rel="noreferrer"
               onClick={() => yandexMetrikaHandler('appDownloadClick')}
            >
                <div className={cc(styles.red_button_content, styles.download_app_button_content)}>
                    Скачать приложение
                </div>
            </a>
        </div>
    )
};