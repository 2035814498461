export const beautyTitles = [
    'Крем-депилятор Батист Огуречный 100мл',
    'Экспресс-Кондиционер для волос Gliss Kur Экстремальное Восстановление 200мл',
    'Зубная паста R.O.C.S. ProActive кальций 94мл',
    'Шампунь для волос Gliss Kur Экстремальное восстановление 400мл',
    'Крем-депилятор Батист Йогуртовый 100мл',
    'Зубная щетка R.O.C.S. Black Edition Classic средней жесткости',
    'Крем-гель для душа Fa Delight Ритмы острова Бали 250мл',
    'Гель для душа Fa Fun Ритмы острова Гавайи 250мл',
    'Гель для душа Fa Dream Ритмы острова Фиджи 250мл',
    'Кондиционер для волос Gliss Kur безупречно длинные 200мл',
    'Бальзам для волос Gliss Kur Экстремальное восстановление 360мл',
    'Бритва Zollider Force 3 max одноразовая 3 лезвия 4+1шт',
    'Гель для душа Fa ритмы Бразилии 250мл',
    'Маска для волос Gliss Kur Укрепляющий микс 4в1 Восстанавливающая 400мл',
    'Станок Zollider Urban Lite 3 лезвия с 1 кассетой',
    'Маска для волос Gliss Kur Совершенство блонд 200мл',
]

export const meatTitles = [
    'Сосиски Вязанка Молочные 450г',
    'Сосиски Вязанка Сливочные 450г',
    'Колбаса Великолукский МК Докторская вареная 500г',
    'Сосиски Великолукский МК Молочные 330г',
    'Сосиски Индилайт Молочные из индейки вареные 440г',
    'Бекон Индилайт из индейки вареный в нарезку 120г',
    'Ветчина Индилайт Кампана из бедра индейки вареная 400г',
    'Колбаса Индилайт Вареная докторская из индейки 400г',
    'Сосиски Индилайт Грандос вареные 440г',
    'Сосиски Индилайт Сливочные из индейки вареные 440г',
    'Сосиски Индилайт вареные из индейки с сыром 440г',
    'Сосиски Индилайт Лайт 240г',
    'Колбаса Индилайт Грандос с филе индейки вареная 400г',
    'Колбаса Царицыно говяжья Особая полукопченая 500г',
    'Сосиски Владимирский Стандарт Баварские 420г',
    'Колбаса Велком Докторская Гост вареная мини 500г',
    'Колбаса Велком Телячья Гост мини вареная 500г',
    'Сосиски Павловская Слобода Докторские 440г',
    'Колбасное ассорти Велком Брауншвейгская Велкомовская Миланская сырокопченое нарезка 90г',
    'Филе сельди Русское море Селедочка к картошке Традиционный посол 400г',
    'Крабовые палочки Русское море охлажденные 200г',
    'Икра минтая Авистрон соленая натуральная 1 сорт',
    'Стейк Алабама из свинины в маринаде',
    'Рулетики куриные с пикантной начинкой',
    'Сосиски Дым Дымыч с говядиной 400г',
    'Шпикачки Дым Дымычъ деревенские 500г',
    'Колбаса Дым Дымычъ С вяленными томатами сыро-копченая полусухая 200г',
    'Ветчина Индилайт Кампана из грудки индейки 120г',
    'Колбаса Дым Дымыч Сервелат с дроблен орехом 290г'
]

export const cookingTitles = [
    'Салат Норвежский',
]

export const semifinishedTitles = [
    'Пельмени Цезарь с мясом бычков 750г',
    'Пельмени Горячая Штучка Бульмени с говядиной и свининой 900г',
    'Пельмени Горячая Штучка Бульмени Большие с говядиной и свининой 900г',
    'Пельмени Цезарь Классика 800г',
    'Пельмени Цезарь Гордость Сибири 800г',
    'Наггетсы Индилайт индейка класическая запеченая 260г',
    'Пельмени Сытый П с сочным мясом 700г',
]

export const drinksTitles = [
    'Напиток газированный Черноголовка Байкал 500мл',
    'Напиток Черноголовка Дюшес газированный 500мл',
    'Напиток Черноголовка Тархун газированный 500мл',
    'Кофе растворимый Ambassador Platinum 190г',
    'Кофе растворимый Черная карта Exclusive Brasillia 95г',
    'Кофе растворимый Ambassador Platinum 95г',
    'Кофе растворимый Черная Карта Gold 95г',
    'Кофе в зернах Черная Карта Арабика 250г',
    'Кофе молотый Черная Карта 250г',
    'Напиток Фрутмотив Тропик 1.5л',
    'Напиток Дюшес Черноголовка газированный 500мл',
    'Напиток Черноголовка Байкал газированный 500мл',
    'Кофе растворимый Московская Кофейня на Паяхъ Суаре 75г',
    'Кофе Черная Карта Gold Растворимый 190г',
    'Кофе растворимый Черная карта Gold 150г',
    'Пиво Балтика №0 безалкогольное 450мл',
    'Напиток пивной Балтика №0 Пшеничное нефильтрованное 0.5% 450мл',
    'Вода Псыж минеральная лечебно-столовая газированная 1л',
    'Напиток Fresh Bar Alfa Cola 480мл',
    'Вода Легенда Гор Архыз питьевая негазированная 1.5л',
    'Напиток Старые Добрые Традиции Тархун 500мл',
    'Вода Легенда Гор Архыз питьевая минеральная газированная 1.5л',
    'Чай зеленый Nestea Малина 1.5л',
    'Напиток энергетический Tornado Storm 450мл',
    'Напиток E-ON Almond Rush энергетический 450мл',
    'Напиток E-ON Ginger Grush энергетический 450мл',
    'Напиток E-ON Black Power Original энергетический 450мл',
    'Напиток Fresh Bar Orange Blast 480мл',
    'Напиток Fresh Bar Citrus Ice 480мл',
    'Энергетический напиток Gorilla 450мл',
    'Цикорий растворимый Большая Чашка Классический 85г',
    'Кофе молотый в растворимом Московская кофейня на паяхъ Арабика 95г',
    'Напиток пивной Балтика №0 Грейпфрут безалкогольное 0.5% 330мл',
    'Энергетический напиток Gorilla Апельсин 400мл',
    'Напиток E-On энергетический киви-ананас газированный 450мл',
    'Чай черный Nestea Манго и Ананас 1.5л',
    'Кофе растворимый Fresco Arabica blend 100г',
    'Кофе молотый в растворимом Fresco Doppio 100г',
    'Напиток Hot Cat энергетический Barberry 450мл',
    'Напиток Черноголовка Тархун газированный 500мл',
    'Напиток Tornado Energy Skill энергетический 473мл',
    'Энергетический напиток Red Bull без сахара 250мл',
    'Кофе зерновой Fresco Arabica 200г',
    'Кофе молотый Fresco Arabica 200г',
    'Напиток пивной Балтика светлый Малина с витаминами 0.0% 330мл',
    'Напиток Fresh Bar Альфа Кола негазированный 1.5л',
    'Напиток Fresh Bar Цитрус Айс негазированный 1.5л',
    'Напиток Hotcat энергетический киви-фейхоа 450мл',
    'Энергетический напиток Gorilla Mango газированный 450мл',
    'Напиток Tornado Coconut газированный 450мл',
    'Напиток Tornado Bubble газированный 450мл',
    'Напиток E-On Energy Drin Danger Berry газированный 450мл',
    'Напиток Черноголовка Мандарин газированный 500мл',
    'Энергетический напиток Gorilla Lychee Pear Личи и груша 450мл',
    'Напиток Tornado Max Bubble 1л',
    'Напиток Черноголовка Кола сильногазированный 0,5л',
    'Кофе в зернах Московская кофейня на паяхъ Арабика 250г',
    'Энергетический напиток Red Bull 250мл',
    'Вода Рычал-Су минеральная лечебно-столовая газированная 500мл',
    'Вода Рычал-Су минеральная лечебно-столовая газированная 1л',
    'Напиток Старые Добрые Традиции Дюшес 500мл',
    'Напиток Фрутмотив Ягодный микс 1.5л',
    'Кофе растворимый Московская кофейня на паяхъ Суаре 95г',
]

export const cakeTitles = [
    'Конфеты Конти Княжеские',
    'Конфеты Конти Золотая Лилия',
    'Драже M&Ms с арахисом 45г',
    'Драже M&Ms с молочным шоколадом 45г',
    'Шоколадный батончик Snickers 50.5г',
    'Жевательная резинка Orbit White Bubblemint 13.6г',
    'Десерт Konti Bonjour souffle Груша с французской ванилью 232г',
    'Конфеты Джек Вафельные со вкусом какао Конти',
    'Шоколад Milka Wholenut Caramel Молочный с фундуком и карамелью 300г',
    'Конфеты Snickers Minis пломбир',
    'Печенье-сэндвич Конти Супер Контик кокос 100г',
    'Драже M&Ms с арахисом 80г',
    'Драже M&Ms шоколад 80г',
    'Драже MMS Криспи 70г',
    'Шоколадный батончик Snickers 160г',
    'Паста Milka ореховая с какао Фундук 350г',
    'Батончик Snickers Super шоколадный 80г',
    'Косичка с вишневой начинкой 800г',
    'Блин Сытый Папа Хот Дог 90г',
    'Пирожное Медвежонок Барни Шоколад 5шт*30г',
    'Пирожное Медвежонок Барни молоко 5шт*30г',
    'Печенье сдобное Ореховое Кольцо 300г',
    'Жевательная резинка Orbit Сочный арбуз 13.6г',
    'Жевательная резинка Orbit Клубника-банан 13.6г',
    'Шоколадный батончик Snickers Лесной Орех 2шт*40.5г',
    'Печенье-сэндвич Konti Супер Контик Шоколадный вкус 100г',
    'Торт Йогуртовый бисквитный 800г',
    'Леденец Chupa Chups Мороженое 12г',
    'Жевательная резинка Orbit Сладкая мята 13.6г',
    'Жевательная резинка Orbit White Нежная мята 13.6г',
    'Шоколад Ritter Sport Молочный с ромом изюмом и орехами 100г',
    'Жевательные конфеты Fruittella Ассорти клубника апельсин лимон 41г',
    'Жевательные конфеты Fruittella со вкусом Клубничного йогурта 41г',
    'Леденец Chupa Chups Тропик-экзотик 12г',
    'Шоколад Ritter Sport Молочный Лесной орех 100г',
    'Десерт Konti Bonjour souffle со вкусом ягод 232г',
    'Торт бисквитный Йогуртовый 800г',
    'Конфеты Konfesta кокосовая начинка глазированные',
]

export const milkTitles = [
    'Сыр Galbani Моцарелла 45% 125г',
    'Сыр Galbani Моцарелла Мини 45% 150г',
    'Масло сливочное Щедрая Русь шоколадное 62% 170г',
    'Сыр Радость Вкуса Легкий 35% 200г',
    'Сыр Радость Вкуса Топленое молочко 45% 200г',
    'Сыр плавленый President Сливочный 45% 400г',
    'Сыр плавленый President Сливочный 40% 150г',
]

export const fruitTitles = [
    'Зелень Ассорти Лук Укроп и Петрушка 100г',
    'Фасоль стручковая Bonduelle зеленая резаная тонкая быстрозамороженная 400гр',
    'Шпинат мини 75г',
    'Смесь овощная Bonduelle для жарки ВОК по-азиатски 400г',
    'Картофель мытый экстра',
    'Салат Листовой в горшочке 80г',
]

export const groceryTitles = [
    'Соус Mivimex Кавказский особо острый 200г',
    'Соевый соус Mivimex с перцем чесноком 200мл',
    'Масло льняное Здоровое меню нерафинированное 250мл',
    'Лапша Царь домашняя 450г',
    'Лапша Царь гречневая 400г',
    'Смесь для кекса Печем Дома Ваниль 300г',
    'Масло подсолнечное Здоровое Меню нерафинированное 900мл',
    'Кекс Печем Дома Апельсиновый 300г',
]

export const feedTitles = [
    'Влажный корм для кошек Felix Аппетитные кусочки  в желе с лососем 85г',
    'Влажный корм для кошек Felix Аппетитные кусочки в желе с курицей 85г',
    'Корм для кошек Felix Говядина желе 85г ',
    'Сухой корм для кошек Purina One для стерилизованных кошек с говядиной и пшеницей 750г',
    'Сухой корм для кошек Purina One Для стерилизованных кошек с лососем и пшеницей 1.5кг',
    'Сухой корм для собак Purina One при активном образе жизни с говядиной и рисом 600г',
    'Влажный корм для собак Pedigree миниатюрных пород Паштет с курицей 80г',
    'Влажный корм для котят Purina One в соусе с курицей и морковью 75г',
    'Корм для кошек Purina One Для стерилизованных кошек Курица с зеленой фасолью 75г',
    'Корм для кошек Purina One Говядина с морковью 75г',
    'Влажный корм для собак Purina One При активном образе жизни в соусе с уткой и пастой 85г',
    'Влажный корм для собак Pedigree с говядиной в соусе Для всех пород 85г',
    'Влажный корм для собак Pedigree всех пород С кроликом и индейкой в соусе 85г',
    'Влажный корм для собак Pedigree всех пород Говядина с ягненком в соусе 85г',
    'Влажный корм для кошек Felix Sensanions Супервкус в желе с говядиной и сыром 75г',
    'Влажный корм для кошек Felix Sensanions Супервкус в желе с индейкой и ягодами 75г',
    'Влажный корм для кошек Whiskas полнорационный Желе с говядиной и ягненком 75г',
    'Влажный корм для кошек Whiskas полнорационный Желе с говядиной и кроликом 75г',
    'Влажный корм для кошек Whiskas полнорационный Желе с курицей 75г',
    'Влажный корм для кошек Whiskas полнорационный Желе с курицей и индейкой 75г',
]

export const chemistryTitles = [
    'Пятновыводитель Vanish Gold Oxy Action для чистки ковров 500мл',
    'Стиральный порошок Gardenica для цветного белья 900г',
    'Таблетки для посудомоечных машин Gardenica All In 1 20шт',
    'Гель Gardenica для мытья посуды Золотая Тыква 500мл',
    'Средство для чистки ковров Vanish Антибактериальный 450мл',
]
