import React from "react";

export const PartialYellowBalloon = () => {

    return (
        <svg width="196" height="273" viewBox="0 0 196 273" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M90.2679 232.374C122.084 241.437 191.998 200.79 209.749 142.573C223.378 97.8733 199.815 40.9067 152.509 26.4826C105.202 12.0585 53.8641 46.1874 40.235 90.8867C23.9391 144.332 55.7804 217.2 86.3963 230.953C86.1931 231.263 86.135 231.664 86.2821 232.042L86.3068 232.105C86.5312 232.68 86.1491 233.325 85.5591 233.508C84.0161 233.986 81.8648 235.134 80.5649 237.417C79.3502 238.177 77.2486 240.234 78.5605 242.379C80.2003 245.06 83.7974 246.157 87.4401 246.298C91.0828 246.439 94.4786 245.778 94.4802 243.356C94.4817 240.933 93.5604 239.925 93.1784 239.566C93.08 239.474 93.0473 239.273 92.9997 238.981C92.8656 238.157 92.6132 236.607 90.4455 234.705C90.3381 234.611 90.2396 234.504 90.1582 234.387C89.7297 233.77 89.7752 232.941 90.2679 232.374Z"
                  fill="#FFD43B"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M90.2679 232.374C122.084 241.437 191.998 200.79 209.749 142.573C223.378 97.8733 199.815 40.9067 152.509 26.4826C105.202 12.0585 53.8641 46.1874 40.235 90.8867C23.9391 144.332 55.7804 217.2 86.3963 230.953C86.1931 231.263 86.135 231.664 86.2821 232.042L86.3068 232.105C86.5312 232.68 86.1491 233.325 85.5591 233.508C84.0161 233.986 81.8648 235.134 80.5649 237.417C79.3502 238.177 77.2486 240.234 78.5605 242.379C80.2003 245.06 83.7974 246.157 87.4401 246.298C91.0828 246.439 94.4786 245.778 94.4802 243.356C94.4817 240.933 93.5604 239.925 93.1784 239.566C93.08 239.474 93.0473 239.273 92.9997 238.981C92.8656 238.157 92.6132 236.607 90.4455 234.705C90.3381 234.611 90.2396 234.504 90.1582 234.387C89.7297 233.77 89.7752 232.941 90.2679 232.374Z"
                  fill="black" fillOpacity="0.1"/>
            <path
                d="M94.2513 193.993C137.436 207.16 184.498 178.297 199.369 129.527C207.223 103.768 204.526 77.6069 193.869 56.7145C183.617 42.6773 169.51 31.6664 152.209 26.3911C106.581 12.4787 57.1339 44.0708 41.6364 86.6729C29.6503 133.886 52.4913 181.26 94.2513 193.993Z"
                fill="#FFD43B"/>
            <path
                d="M74.6976 64.4755C82.4711 52.7354 97.8412 43.2806 106.541 39.1584C107.147 38.8712 107.84 38.8318 108.486 39.0332C110.945 39.8008 111.15 43.3206 108.888 44.5088C95.4356 51.5761 88.4213 60.9325 85.5156 67.2248C84.4636 69.5028 82.4436 71.3334 79.9337 71.6303C75.5983 72.1433 72.305 68.0888 74.6976 64.4755Z"
                fill="#FFD43B"/>
            <path
                d="M74.6976 64.4755C82.4711 52.7354 97.8412 43.2806 106.541 39.1584C107.147 38.8712 107.84 38.8318 108.486 39.0332C110.945 39.8008 111.15 43.3206 108.888 44.5088C95.4356 51.5761 88.4213 60.9325 85.5156 67.2248C84.4636 69.5028 82.4436 71.3334 79.9337 71.6303C75.5983 72.1433 72.305 68.0888 74.6976 64.4755Z"
                fill="white" fillOpacity="0.8"/>
        </svg>
    )
};