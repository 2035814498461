import React from "react";

export const Arrow = () => {

    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="15" cy="15" rx="15" ry="15" transform="rotate(-180 15 15)" fill="#ED0E00"/>
            <path d="M9.28564 15.0001L20.7142 15.0001M20.7142 15.0001L16.9047 10.7144M20.7142 15.0001L16.9047 19.2858"
                  stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};